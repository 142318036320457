<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1 && control.synopticComponent">
      <ControlBaseProperties
        v-model="control"
        @setExpression="buildExpression($event)"
        :hasExpression="expression != ''"
      />
      <ControlSpecificPanel :control="control">
        <div class="form-group form-group-sm">
          <label for="">{{ $t("titles.form") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="formName"
              ref="formName"
            />
            <div
              class="input-group-addon btn"
              @click.stop.prevent="
                formName = '';
                $refs.formName.focus();
              "
            >
              <i class="fa fa-close"></i>
            </div>
          </div>
        </div>
        <!-- data input does has fixed expression targeting the data id and can not be edited -->
        <div class="form-group form-group-sm">
          <label for="">{{ $t("expression") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              :value="expression"
              disabled
              style="background-color: whitesmoke"
            />
            <div
              class="input-group-addon btn"
              @click.stop.prevent="expression = ''"
            >
              <i class="fa fa-close"></i>
            </div>
          </div>
        </div>
        <DataValueCommandStatus :control="control" />
        <TogglePanel :title="$t('control_status')" layout="simple">
          <MultiColorsSelector
            class="multi-color-selector"
            v-model="control.synopticComponent.errorStyle"
            :label="$t('synoptic.error_state')"
          />
        </TogglePanel>
        <div class="form-group form-group-sm" style="margin-top: 10px">
          <label for="">{{ $t("label") }}</label>
          <input
            type="text"
            class="form-control form-group-sm"
            :value="control.synopticComponent.label"
            @input="updateLabel($event.target.value)"
          />
        </div>
        <div class="form-group form-group-sm">
          <label for="">{{ $tc("value", 2) }}</label>
          <div class="input-group">
            <div class="input-group-addon input-group-addon-check">
              <i class="fa fa-check-square-o"></i>
              <span class="small">assinalado</span>
            </div>
            <input
              type="text"
              class="form-control form-group-sm"
              :value="control.synopticComponent.values.checked"
              @input="updateValue('checked', $event.target.value)"
            />
          </div>
          <div class="input-group">
            <div class="input-group-addon input-group-addon-check">
              <i class="fa fa-square-o"></i>
              <span class="small">não assinalado</span>
            </div>
            <input
              type="text"
              class="form-control form-group-sm"
              :value="control.synopticComponent.values.unchecked"
              @input="updateValue('unchecked', $event.target.value)"
            />
          </div>
        </div>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import DataValueCommandStatus from "./data-value-command-status.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import MultiColorsSelector from "@/components/editor/multi-colors-selector";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
export default {
  name: "DataValueCheckbox",
  extends: BaseControl,
  components: {
    ControlBaseProperties,
    DataValueCommandStatus,
    ControlSpecificPanel,
    MultiColorsSelector,
    TogglePanel
  },
  data() {
    return {
      control: {},
      skipUpdate: false
    };
  },
  computed: {
    dataListParser() {
      return function(list) {
        return list.filter((item) => {
          return !item.read_only;
        });
      };
    },
    expression: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "expression", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.expression || "";
      }
    },
    formName: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "formName", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.formName ?? "";
      }
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl(val);
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    updateLabel(value) {
      this.control.synopticComponent.label = value;
    },
    updateValue(attr, value) {
      let entry = this.control.synopticComponent.values || {
        checked: 1,
        unchecked: 0
      };
      entry[attr] = value;
      this.control.synopticComponent.values = entry;
    },
    updateControl(control) {
      this.$store.dispatch("synoptic/updateControl", {
        id: control.id,
        control: control,
        noMerge: true
      });
    },
    buildExpression(source) {
      this.expression = source
        .replace(/\$\('[\d]+/, "$('.")
        .replace(/\?\.name/, ".id");
      this.$utils.notifyUser(this, this.$t("titles.expression_created"));
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>
<style scoped>
.input-group-addon-check {
  min-width: 110px;
  color: #367fa9;
  opacity: 0.8;
  text-align: left;
  padding: 0 5px;
}

.input-group-addon-check > span {
  padding-left: 5px;
  vertical-align: top;
}

.input-group-addon-check:hover {
  cursor: not-allowed;
}
.multi-color-selector {
  margin-bottom: 0.5em;
}
</style>
